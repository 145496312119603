import React, { Component, useEffect } from 'react';
import logoMin from './logoMin.svg';
import logo from './logo.svg';
import nettbil from './nettbil.svg';
import biosort from './biosort.png';
import './App.css';

const App = () => {
  useEffect(() => {
    // Add glitch data-text attribute to all h2 elements
    const headings = document.querySelectorAll('h2');
    headings.forEach(heading => {
      heading.setAttribute('data-text', heading.textContent);
    });
  }, []);

  return (
    <div className="App">
      {/* Header Section */}
      <header className="header">
        <img src={logoMin} alt="Flow Lab Logo" className="header-logo" />
        <nav className="nav">
          <a href="#about">About_Us</a>
          <a href="#services">Services</a>
          <a href="#customers">Customers</a>
          <a href="#contact">Contact</a>
          <a href="https://blog.flowlab.no">Blog</a>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <img src={logo} alt="Flow Lab" className="main-logo" />
        <p>NEURO-ENHANCED MACHINE LEARNING & AI SOLUTIONS</p>
        <div className="loading-bar"></div>
        <a href="#contact" className="cta-button">
          INITIALIZE NEURAL LINK
          <span className="cyber-cursor"></span>
        </a>
      </section>

      {/* About Us Section */}
      <section id="about" className="content-section">
        <h2>SYSTEM: ABOUT_US</h2>
        <p>
          > FLOW_LAB: NEURAL NETWORK ARCHITECTS AND DIGITAL CONSCIOUSNESS ENGINEERS.
          WE WEAPONIZE YOUR DATA THROUGH PREDICTIVE MODELS INCLUDING MARKET PRICE ALGORITHMS
          AND NEURAL LEAD SCORING TO MAXIMIZE REVENUE STREAMS. STAY AHEAD IN THE DIGITAL WAR
          WITH OUR AUGMENTED AI STRATEGIES DESIGNED TO ACCELERATE CORPORATE DOMINANCE.
        </p>
      </section>

      {/* Services Section */}
      <section id="services" className="content-section">
        <h2>AI & ML PROTOCOLS</h2>
        <div className="services">
          <div className="service">
            <h3>PREDICTIVE MODELING</h3>
            <p>Advanced predictive neural networks for market domination. Our price prediction algorithms give you the edge in competitive markets.</p>
          </div>
          <div className="service">
            <h3>NEURAL LEAD SCORING</h3>
            <p>Augment your sales process with bio-digital lead scoring. Identify high-value targets with precision neural networks.</p>
          </div>
          <div className="service">
            <h3>AI CONSCIOUSNESS</h3>
            <p>Harness the power of synthetic consciousness to solve complex business problems. Expert neural architecture design and implementation.</p>
          </div>
          <div className="service">
            <h3>MACHINE LEARNING SYSTEMS</h3>
            <p>Custom neural networks tailored to your corporate needs - from predictive analytics to autonomous decision automation.</p>
          </div>
          <div className="service">
            <h3>FLOW MLOps</h3>
            <p>Neural MLOps infrastructure to deploy and scale your machine consciousness models with maximum efficiency and network security.</p>
          </div>
          <div className="service">
            <h3>DIGITAL INSIGHT MINING</h3>
            <p>Transform data streams into tactical neural insights with our advanced analytics and synthetic intelligence engines.</p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="customers" className="content-section">
        <h2>CLIENT NETWORK</h2>
        <div className="customers">
          <div className="customer">
            <a href="https://nettbil.no" alt="Nettbil link">
              <img src={nettbil} alt="Nettbil" className="customer-img" />
            </a>
            <p>FLOW_LAB has integrated critical neural networks into Nettbil's application platform, establishing AI/ML neural frameworks. The first-gen predictive algorithms have boosted sales conversion by 47% through advanced pattern recognition.</p>
          </div>
          <div className="customer">
            <a href="https://biosort.no" alt="Biosort link">
              <img src={biosort} alt="Biosort" className="customer-img" />
            </a>
            <p>FLOW_LAB engineered a high-bandwidth neural data pipeline for transferring multi-terabyte image clusters from edge systems to core data nodes. We deployed a synthetic vision annotation system for training advanced computer vision neural networks.</p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="content-section">
        <Contact />
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <p>CONNECT TO OUR NETWORK: <a href="https://www.linkedin.com/company/flowlab-no">LINKEDIN</a></p>
        <p>&copy; 2024-2077 FLOW_LAB | OSLO MEGACITY, NORWAY</p>
      </footer>
    </div>
  );
};

class Contact extends Component {
  render() {
    return <div className="address">
      <h2>NEURAL_UPLINK</h2>
      <p>COMM-LINK: <a href="mailto:kontakt@flowlab.no">kontakt@flowlab.no</a> | NET-SIGNAL: +47 45064756</p>
      <p>FLOW_LAB AS - CORP. ID: 917 482 209<br />HQ: Rebel, Universitetsgata 2, 0164 Oslo Sector</p>
    </div>
  }
}

export default App;